import React, { Component } from 'react'
import { connect } from "react-redux";

import './PresentationPage.scss'
import Loader from "../Common/Loader";
import CustomerVerificationPage from "./CustomerVerificationPage";

class PresentationPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    render() {
        const { history, seasons, auth: { canPreorder, preorderAuthenticated }, customer, auth } = this.props

        if(!canPreorder) {
            history.push('/app')
        }

        if(canPreorder && !preorderAuthenticated) {
            return <CustomerVerificationPage history={history} />
        }

        if(!seasons || !seasons.presentationSeasons || !seasons.presentationSeasons.length) {
            return <Loader/>
        }

        const urlParams = new URLSearchParams(window.location.search);
        const factoryParam = urlParams.get('factory');
        const isSimonePreorder = factoryParam === 'Simone';

        const goToSimoneFamilies = (season) => {
            localStorage.setItem("selectedSeason", season.softoneCode);
            history.push(`/app/presentation/season/${season.softoneId}?factory=Simone`)
        }

        return <div className="presentation-page">
            {isSimonePreorder ? '' : ((customer && customer.activeCustomer && customer.activeCustomer.customer && customer.activeCustomer.customer.length && customer.activeCustomer.customer[0]
                        && customer.activeCustomer.customer[0].billingAddress && customer.activeCustomer.customer[0].billingAddress.softoneCountryCode === 1000) ||
                    ( auth.activeUser && auth.activeUser.allAccess && auth.activeUser.b2bView === 'GR' ) ||
                    ( auth.activeUser && auth.activeUser.role === 'admin' && auth.activeUser.b2bView === 'GR' ) ||
                    ( auth.activeUser && auth.activeUser.role === 'salesman' && auth.activeUser.softoneCountryCode === 1000 )
                ) && <div onClick={() => history.push(`/app/presentation?factory=Simone`) }><img className="simone-logo-img-header" src="/simoneLogo.svg"/></div>
            }
            <div className="seasons-list-container">
                <ul className="seasons-list">
                    { isSimonePreorder ? seasons.presentationSimoneSeasons
                        .sort((a, b) => b.softoneCode.replace(/\D/g,'') - a.softoneCode.replace(/\D/g,''))
                        .map((season, index) => {
                            return <li className="seasons-item" key={index}>
                                <div
                                    className="season-link"
                                    onClick={() => goToSimoneFamilies(season)}
                                >
                                    <h3>{`${season.summerWinter} ${season.year}`}</h3>
                                    <img src={season.image} alt={season.softoneCode}/>
                                </div>
                            </li>
                        })
                        :
                        seasons.presentationSeasons
                        .sort((a, b) => b.softoneCode.replace(/\D/g,'') - a.softoneCode.replace(/\D/g,''))
                        .map((season, index) => {
                        return <li className="seasons-item" key={index}>
                            <div
                                className="season-link"
                                onClick={() => history.push(`/app/presentation/season/${season.softoneId}`)}
                            >
                                <h3>{`${season.summerWinter} ${season.year}`}</h3>
                                <img src={season.image} alt={season.softoneCode}/>
                            </div>
                            <div
                                className="campaign-link"
                                onClick={() => history.push(`/app/presentation/campaign/${season.softoneId}`)}
                            >
                                <img src={season.campaignImage} alt={season.softoneCode}/>
                            </div>
                        </li>
                    })}
                </ul>
            </div>
        </div>
    }
}

const mapStateToProps = ({season, settings, auth, customer}) => {
    return {
        auth,
        seasons: season,
        settings: settings.settings,
        customer
    }
}

export default connect(mapStateToProps, {})(PresentationPage)
